.rulebase-name {
    width: 300px
}

.EditRuleBase_Title {
    padding-bottom: 10px;
}

.Button {
    padding-top: 5px;
    padding-bottom: 5px;
}

.Save {
    padding-top: 5px;
}

.New {
    padding-top: 5px;
}

.form-group {
    overflow: scroll;
    max-height: calc(100vh - 120px)
}

.Description {
    padding-top: 2px;
    padding-bottom: 3px;
    font-size:small;
    color: rgb(50, 100, 200);
}