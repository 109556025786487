.sidenav {
    height: 100%; /* Full-height: remove this if you want "auto" height */
    width: 130px; /* Set the width of the sidebar */
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: rgb(233, 233, 233); /* Black */
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 20px;
  }
  
  /* The navigation menu links */
  .sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 20px;
    color: #010101;
    display: block;
  }
  
  .Title {
    width: 100%;
    font-size: 16px;
    text-align: center;
    color: black;
    padding-bottom: 10px;
    font-family: Helvetica, sans-serif;
  }

  /* When you mouse over the navigation links, change their color */
  .sidenav a:hover {
    color: #f1f1f1;
  }

  #logout-button {
    color: #FFF;
    padding: 6px 8px 6px 16px;
    font-size: 20px;
    line-height: unset;
  }
  
  /* Style page content */
  .main {
    margin-left: 160px; /* Same as the width of the sidebar */
    padding: 0px 10px;
  }
  
  /* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }

