.selectOperator{
    width: 210px
    
}
.selectVariable{
    width: 250px
}
.Box3{
    padding: 7px
}
.Box4 {
    padding-right: 10px
}

.AndButton {
    padding-top: 5px;
}