.Rules {
    padding-top: 20px;
    padding-bottom: 15px;
}
.RuleButtons {
    padding: 10px 0;
}

.RuleButtons button {
    margin-right: 8px;
}

.RuleBaseName {
    padding-top: 50px;
    padding-bottom: 15px;
}