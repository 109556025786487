.form-control {
    padding-right: 5px
}
.textarea {
    display: block;
    width: 100%;
    overflow: hidden;
    resize: both;
    min-height: 40px;
    line-height: 20px;
    padding: 7px
  }
.Box{
    padding: 7px
}

.RuleForm {
    padding-top: 50px
}

.WhenTitle {
    padding-bottom: 5px;
}

.Form__title {
    padding-bottom: 5px;
    font-family: Arial, Helvetica, sans-serif;
}

.RuleNumber {
    padding-bottom: 10px;
}

.BackButton {
    padding-left: 10px;
}

.Box5 {
    margin-bottom: 10px;
}

.Box6 {
    padding-right: 10px;
    margin-top: 10px;
    justify-content: left
}