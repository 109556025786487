.ruleBases {
    padding-bottom: 10px;
}

.ruleBase-link {
    display: block;
    color: black;
    text-decoration: none;
    margin: 4px 0;
}

.ruleBase-link:hover {
    color: rgb(0 0 0 / 70%);
}

.RuleBaseGroup {
   align-self: left;
}

.Table_Rulebase {
    margin-top: 50px;
}